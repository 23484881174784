import ShowcaseItem from 'components/showcase/ShowcaseItem';

import {
  ApiDropSpotlightFragment,
  ApiEditionSpotlightFragment,
  ApiMomentFragment,
  ApiNftSpotlightFragment,
} from 'gql/api/api-fragments.generated';
import { ApiMomentCreators } from 'gql/api/queries/moment-creators.generated';
import { mapDropSaleConfigurationToDropSale } from 'schemas/parse/drop-sale';
import { mapEditionSaleConfigurationToEditionSale } from 'schemas/parse/edition-sale';
import { mapApiNftMediaToShowcaseMedia } from 'utils/media';

import { WorldOverview } from 'types/World';

export type SpotlightContentType =
  | {
      type: 'DROP';
      content: {
        collection: ApiDropSpotlightFragment;
        world: WorldOverview | null;
        moment: ApiMomentFragment | null;
      };
    }
  | {
      type: 'EDITION';
      content: {
        collection: ApiEditionSpotlightFragment;
        world: WorldOverview | null;
        moment: ApiMomentFragment | null;
      };
    }
  | {
      type: 'NFT';
      content: {
        nft: ApiNftSpotlightFragment;
        world: WorldOverview | null;
        moment: ApiMomentFragment | null;
      };
    }
  | {
      type: 'MOMENT';
      content: {
        moment: ApiMomentFragment;
        world: WorldOverview;
        creators: ApiMomentCreators;
      };
    }
  | {
      type: 'UNKNOWN';
      content: null;
    };

export function SpotlightContent(
  props: Exclude<SpotlightContentType, { type: 'UNKNOWN' }>
) {
  const getShowcaseUi = () => {
    switch (props.type) {
      case 'NFT':
        return (
          <ShowcaseItem.Nft
            nft={{
              ...props.content.nft,
              media: mapApiNftMediaToShowcaseMedia(props.content.nft.media),
              description: props.content.nft.description || '',
            }}
            world={props.content.world}
            moment={props.content.moment}
            context="EXTERNAL"
          />
        );
      case 'DROP': {
        const sale = mapDropSaleConfigurationToDropSale(
          props.content.collection.saleConfiguration
        );

        return (
          <ShowcaseItem.Drop
            drop={props.content.collection}
            sale={sale}
            world={props.content.world}
            moment={props.content.moment}
            context="EXTERNAL"
          />
        );
      }
      case 'EDITION': {
        if (!props.content.collection.editionSale) {
          return null;
        }

        const sale = mapEditionSaleConfigurationToEditionSale(
          props.content.collection.editionSale
        );

        if (sale.type === 'TIMED_EDITION') {
          return (
            <ShowcaseItem.TimedEdition
              edition={props.content.collection}
              world={props.content.world}
              moment={props.content.moment}
              sale={sale}
              context="EXTERNAL"
            />
          );
        } else {
          return (
            <ShowcaseItem.LimitedEdition
              edition={props.content.collection}
              world={props.content.world}
              moment={props.content.moment}
              sale={sale}
              context="EXTERNAL"
            />
          );
        }
      }
      case 'MOMENT': {
        return (
          <ShowcaseItem.MomentConnected
            moment={props.content.moment}
            world={props.content.world}
            context="EXTERNAL"
          />
        );
      }
    }
  };

  return <div>{getShowcaseUi()}</div>;
}
