import DropGridItem from 'components/DropGridItem';
import EditionGridItem from 'components/EditionGridItem';
import FrameGrid from 'components/FrameGrid';
import NftGridItem from 'components/NftGridItem';

import {
  ApiFeaturedWorks,
  useFeaturedWorks,
} from 'gql/api/queries/featured-works.generated';
import { mapDropSaleConfigurationToDropSale } from 'schemas/parse/drop-sale';
import { mapEditionSaleConfigurationToEditionSale } from 'schemas/parse/edition-sale';
import { mapApiMediaToPreviewMediaAsset } from 'utils/media-preview';

interface WorksGridProps {
  works: ApiFeaturedWorks['featuredWorks'];
}

export default function WorksGrid(props: WorksGridProps) {
  const featuredWorksQuery = useFeaturedWorks(undefined, {
    initialData: {
      featuredWorks: props.works,
    },
    select: (data) => data.featuredWorks.items,
  });

  const works = featuredWorksQuery.data || props.works.items;

  return (
    <FrameGrid.Root css={{ maxWidth: '$container' }} maxColumns={4}>
      {works.map((work) => {
        if (work.__typename === 'EditionCollection') {
          if (!work.editionSale) return null;

          return (
            <EditionGridItem
              key={`${work.editionCollectionId}-${work.contractAddress}`}
              version="media"
              world={work.world || undefined}
              edition={{
                ...work,
                id: work.editionCollectionId,
                chainId: work.chainId,
              }}
              sale={mapEditionSaleConfigurationToEditionSale(work.editionSale)}
            />
          );
        }
        if (work.__typename === 'DropCollection') {
          return (
            <DropGridItem
              key={work.id}
              drop={{ ...work, mintPrice: work.dropMintPrice }}
              world={work.world || undefined}
              sale={mapDropSaleConfigurationToDropSale(work.saleConfiguration)}
            />
          );
        }
        if (work.__typename === 'MarketNft') {
          const media = work.media
            ? mapApiMediaToPreviewMediaAsset(work.media)
            : null;
          const optimizedMedia = media
            ? NftGridItem.optimizeMedia(media, { maxColumns: 4 })
            : null;

          /**
           * if there is an active auction or buy now, show the NFT as active
           */
          if (work.activeAuction || work.activeBuyNow) {
            return (
              <NftGridItem
                key={`${work.collectionContractAddress}-${work.tokenId}`}
                nft={{
                  ...work,
                  name: work.nftName,
                  contractAddress: work.collectionContractAddress,
                  saleStartsAt: work.nftSaleStartsAt,
                }}
                world={work.world || undefined}
                media={optimizedMedia}
              />
            );
          }

          /**
           * if there is a last sale, show the NFT as sold
           */
          if (work.lastSale) {
            return (
              <NftGridItem.Sold
                key={`${work.collectionContractAddress}-${work.tokenId}`}
                nft={{
                  ...work,
                  name: work.nftName,
                  contractAddress: work.collectionContractAddress,
                  sale: work.lastSale,
                }}
                media={optimizedMedia}
              />
            );
          }

          /**
           * if there is no active auction, buy now or last sale
           * show the NFT as inactive
           */
          return (
            <NftGridItem
              key={`${work.collectionContractAddress}-${work.tokenId}`}
              nft={{
                ...work,
                name: work.nftName,
                contractAddress: work.collectionContractAddress,
                saleStartsAt: work.nftSaleStartsAt,
              }}
              world={work.world || undefined}
              media={optimizedMedia}
            />
          );
        }
      })}
    </FrameGrid.Root>
  );
}
