export type BasicBookmark = {
  id: string;
  content: { id: number };
};

export const getActiveBookmarkId = <Item extends BasicBookmark>(options: {
  bookmarks: Item[];
  momentId: number;
}): string | null => {
  const bookmark = options.bookmarks.find(
    (bookmark) => bookmark.content.id === options.momentId
  );
  return bookmark ? bookmark.id : null;
};
