import { onGridPx } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';
import NextLink from 'next/link';
import { ReactNode } from 'react';

import Body from 'components/base/Body';
import Button from 'components/base/Button';
import Heading from 'components/base/Heading';

import { LinkHref } from 'types/router';

import Flex from './base/Flex';

interface FeaturedSectionProps {
  children: ReactNode;
  title: string;
  subtitle?: string;
  hasBody?: boolean;
  link?: {
    href: LinkHref;
    text: string;
  };
  id?: string;
}

export default function FeaturedSection(props: FeaturedSectionProps) {
  const { children, link, title, hasBody = true, subtitle, id } = props;
  return (
    <Container id={id}>
      <Body>
        <Flex css={{ alignItems: 'baseline', gap: '$2' }}>
          <Heading weight="medium" size={4} css={{ marginBottom: '$6' }}>
            {title}
          </Heading>
          {subtitle && (
            <Heading
              color="dim"
              weight="medium"
              size={3}
              css={{ marginBottom: '$7' }}
            >
              {subtitle}
            </Heading>
          )}
        </Flex>
      </Body>
      {hasBody ? <Body>{children}</Body> : <>{children}</>}
      {link && (
        <Footer>
          <NextLink href={link.href} passHref prefetch={false}>
            <Button as="a">{link.text}</Button>
          </NextLink>
        </Footer>
      )}
    </Container>
  );
}

const Container = styled('div', {
  borderTop: '1px solid $black5',
  paddingY: onGridPx(12),
  '@bp0': {
    paddingTop: onGridPx(12),
    paddingBottom: onGridPx(30),
  },
});

const Footer = styled('div', {
  display: 'flex',
  marginTop: onGridPx(10),
  justifyContent: 'center',
});
