import NextLink from 'next/link';

import Button from './base/Button';
import Link from './base/Link';
import Text from './base/Text';

export default function PreviewMode() {
  return (
    <Text
      weight="semibold"
      size={2}
      css={{
        boxShadow: '$regular1',
        background: '$white90',
        padding: '$4',
        textAlign: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(9px)',
      }}
    >
      <Text size={4} as="span" css={{ marginRight: '$3' }}>
        ⛳️
      </Text>
      In preview mode
      <NextLink href="/api/preview/disable">
        <Link>
          <Button css={{ marginLeft: '$3' }} size={0}>
            Exit
          </Button>
        </Link>
      </NextLink>
    </Text>
  );
}
