import React from 'react';

import { ApiMomentStatsFragment } from 'gql/api/api-fragments.generated';
import { ApiFeaturedMoments } from 'gql/api/queries/featured-moments.generated';
import { getDpr, optimizeAsset } from 'utils/imgix';
import { getMomentStatus } from 'utils/moment';
import { getPath } from 'utils/router';
import { getTruncatedListOfUsers } from 'utils/strings';

import FrameGridItem from './FrameGridItem';
import MomentDynamicLabel, { MomentDynamicDate } from './MomentDynamicLabel';
import MomentMedia from './MomentMedia';
import PresentedBy from './PresentedBy';
import Box from './base/Box';
import Flex from './base/Flex';
import Heading from './base/Heading';
import Text from './base/Text';

type MomentGridItemProps = {
  moment: ApiFeaturedMoments['featuredMoments']['items'][number]['moment'];
  momentStats?: ApiMomentStatsFragment;
  action?: React.ReactNode;
};

const MomentGridItemScaffolding = FrameGridItem;

function MomentGridItem(props: MomentGridItemProps) {
  const { moment, action, momentStats } = props;

  const optimizedPosterUrl = optimizeAsset(moment.posterUrl, {
    q: 70,
    'max-w': 720,
    'max-h': 720,
    fit: 'fill',
    auto: undefined,
    dpr: getDpr(),
  });

  const href = getPath.moment.page({
    worldSlug: moment.world.slug,
    momentId: moment.id,
  });

  const listOfUsers = moment.creators.items.map(
    (creator) => creator.name ?? ''
  );

  const getDynamicLabel = () => {
    if (momentStats) {
      const momentStatus = getMomentStatus({
        liveActivityCount: momentStats.liveActivityCount,
        isSoldOut: momentStats.isSoldOut,
        startsAt: moment.startsAt,
      });

      return (
        <MomentDynamicLabel
          size={2}
          startsAt={moment.startsAt}
          momentStatus={momentStatus}
          isBrief
        />
      );
    }
    return <MomentDynamicDate size={2} startsAt={moment.startsAt} />;
  };

  return (
    <MomentGridItemScaffolding
      href={href}
      media={<MomentMedia optimizedPosterUrl={optimizedPosterUrl} />}
      details={
        <Flex
          css={{
            justifyContent: 'space-between',
            gap: '$2',
          }}
        >
          <Box
            css={{
              minWidth: 0,
              flexShrink: 1,
            }}
          >
            <Heading ellipsis color="strong" weight="medium" size={3}>
              {moment.name}
            </Heading>
            <Text
              color="dim"
              weight="regular"
              size={2}
              css={{ paddingY: '$1' }}
            >
              {getTruncatedListOfUsers({
                list: listOfUsers,
                total: moment.creators.totalItems,
              })}
            </Text>

            {getDynamicLabel()}

            <PresentedBy name={moment.world.name} size={0} />
          </Box>
          <div
            style={{
              zIndex: 2,
            }}
          >
            {action}
          </div>
        </Flex>
      }
    />
  );
}

function MomentGridItemSkeleton() {
  return <FrameGridItem.Skeleton isLandscape />;
}

MomentGridItem.Skeleton = MomentGridItemSkeleton;
export default MomentGridItem;
