import NextLink from 'next/link';
import { useEffect, useState } from 'react';
import useIsInViewport from 'use-is-in-viewport';

import Grid from 'components/base/Grid';
import Link from 'components/base/Link';
import WorldCard from 'components/cards/worlds/WorldCard';

import useWorldStats from 'hooks/queries/api/use-world-stats';
import useWorldBySlug from 'hooks/queries/use-world-by-slug';
import { getPath } from 'utils/router';

type WorldsGrid = {
  slugs: string[];
};

export default function WorldsGrid(props: WorldsGrid) {
  return (
    <Grid
      css={{
        gridGap: '$4',
        gridTemplateColumns: '1fr',
        '@bp1': {
          gridTemplateColumns: 'repeat(2, 1fr)',
        },
      }}
    >
      {props.slugs.map((slug) => (
        <WorldsGridCard key={slug} slug={slug} />
      ))}
    </Grid>
  );
}

function WorldsGridCard(props: { slug: string }) {
  const { slug } = props;
  const [hasBeenInViewport, setHasBeenInViewport] = useState(false);
  const [isInViewport, targetRef] = useIsInViewport();

  const worldQuery = useWorldBySlug(
    {
      slug,
    },
    {
      retry: false,
    }
  );

  const world = worldQuery.data;
  const worldHref = getPath.world.page(slug);

  const worldId = world?.id ?? 0;

  const statsQuery = useWorldStats(
    {
      id: worldId,
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      enabled: Boolean(worldId) && hasBeenInViewport,
    }
  );
  useEffect(() => {
    if (isInViewport) {
      setHasBeenInViewport(true);
    }
  }, [isInViewport]);

  if (worldQuery.isLoading) {
    return <WorldCard.Skeleton size="large" />;
  }
  if (!world) {
    return null;
  }
  return (
    <NextLink key={worldId} href={worldHref} passHref>
      <Link ref={targetRef}>
        <WorldCard
          size="large"
          moderationStatus={world.moderationStatus}
          imageUrl={world.imageUrl ?? undefined}
          name={world.name}
          backgroundImageUrl={world.coverUrl ?? undefined}
          stats={statsQuery.data ? statsQuery.data : undefined}
        />
      </Link>
    </NextLink>
  );
}
